$btn-color: rgb(99,102,241);

.serviceCont {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 40px;
    padding-bottom: 10rem;
}

.serviceHead {
    text-align: center;
    max-width: 65%;
    margin: auto;
    h1 {
        font-size: 52px;
        color: $btn-color;
    }
    p {
        font-size: 24px;
    }
}

.serviceSect {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 60px;
    align-items: center;
}

.serviceImg {
    flex: 1;
    box-sizing: border-box;

    img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        border-radius: 10px;
    }
}

.serviceText {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    h2 {
        font-size: 32px;
        
    }
    p {
        font-size: 24px;
        margin: 30px 0;
    }
}

.homeBtn {
    background-color: $btn-color;
    border: none;
    color: white;
    padding: 20px 25px;
    border-radius: 999px;
    font-size: 24px;
    cursor: pointer;
    transition: all .15s ease-in-out;
}

@media only screen and (max-width: 768px) {
    .serviceCont {
        padding: 15px;
        padding-bottom: 10rem;
        gap: 60px;
    }
    
    .serviceHead {
        max-width: 100%;
    }
    
    .serviceSect {
        flex-direction: column;
        width: 100%;
        gap: 60px;
        align-items: center;
    }
    
    .serviceImg {
        flex: 1;
        width: 100%;
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px;
        }
    }
}