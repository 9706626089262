$bg-color: rgb(255,255,255);
$txt-color: rgb(30,41,59);
$alt-txt-color: rgb(110, 109, 114);
$btn-color: rgb(99,102,241);

.homeCont {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-bottom: 10rem;
}

.homeSect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
    gap: 20px;
    align-items: center;
    h1 {
        font-size: 42px;
    }
    h3 {
        font-style: italic;
    }

    .sectText {
        display: flex;
        flex-direction: column;
        max-width: 50%;
        gap: 30px;

        .verify {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            img {
                width: 25px;
                height: 25px;
                padding-top: 4px;
            }
        }

        h1 {
            font-size: 72px;
            font-weight: 900;
        }
        p {
            font-size: 24px;
        }
        a {
            width: fit-content;
        }
    }

    .homeBtn {
        background-color: $btn-color;
        border: none;
        color: white;
        padding: 20px 25px;
        border-radius: 999px;
        font-size: 24px;
        cursor: pointer;
        transition: all .15s ease-in-out;
    }
    .homeBtn:hover {
        color: $txt-color;
    }

    .sectImg {
        max-height: 600px;
        overflow: hidden;
        border-radius: 10px;
        max-width: 50%;

        img {
            width: 800px;
            border-radius: 10px;
        }
    }

    .centerText {
        margin: auto;
        text-align: center;
        h1 {
            color: $btn-color;
            font-size: 42px;
        }
    }

    .sectPoints {
        max-width: 250px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        img {
            width: 40px;
            padding-bottom: 10px;
        }
        h2 {
            padding-bottom: 10px;
        }
    }
}

.altSect {
    background-color: rgb(239,239,254);
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 60px 0;

    button {
        padding: 20px 65px !important;
    }

    .altImgCont {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        margin: 40px 0;
    }
    .altSectImg {
        width: 400px;
        height: 300px;
        object-fit: cover;
        border: 4px solid white;
        border-radius: 10px;
    }
}

.imgBufferCont {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 50%;
    justify-content: flex-end;

    .imgBuffer {
        height: 20px;
    }
    img {
        width: 100%;
        border-radius: 10px;
    }
}

.reviewImg {
    width: 50%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
}

.reviewText {
    max-width: 46%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    h3 {
        margin: 20px 0;
        font-size: 24px;
    }
}

@media only screen and (max-width: 768px) {
    .homeSect {
        flex-direction: column;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        text-align: left;

        .sectText {
            max-width: 100%;
            h1 {
                font-size: 42px;
            }
            p {
                font-size: 16px;
            }
        }

        .sectImg {
            max-height: 300px;
            overflow: hidden;
            border-radius: 10px;
            max-width: 100%;
    
            img {
                width: 800px;
                border-radius: 10px;
            }
        }
    }

    .altSect {
        padding: 60px 0;
        text-align: center;
        button {
            padding: 20px 65px !important;
        }
    
        .altSectImg {
            width: 300px;
            height: 200px;
        }
    }

    .imgBufferCont {
        width: 100%;
    }

    .reviewImg {
        width: 100%;
        height: 300px;
    }
    .reviewText {
        max-width: 100%;
    }
    
}