$bg-color: rgb(255,255,255);
$txt-color: rgb(30,41,59);
$btn-color: rgb(99,102,241);
$alt-txt-color: rgb(110, 109, 114);

* {
    padding: 0;
    margin: 0;
    color: $txt-color;
    
    p {
        color: $alt-txt-color;
    }
}

.AppCont {
    position: relative;
    min-height: 100vh;
    
}