.footerCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    background-color: rgb(248,250,252);
    margin-top: 60px;
    position: absolute;
    bottom:0;
    width: 100%;
    box-sizing: border-box;

    img {
        width: 40px;
    }
}

.footerLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        transition: all .15s ease-in-out;
    }
}

@media only screen and (max-width: 768px) {
    .footerCont {
        padding: 20px 15px;
    }
    
    .footerLinks {
        gap: 15px;
    }
}