$btn-color: rgb(99,102,241);

.aboutCont {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 40px;
    padding-bottom: 10rem;
}

.aboutHead {
    text-align: center;
    max-width: 65%;
    margin: auto;
    h1 {
        font-size: 52px;
        color: $btn-color;
    }
    p {
        font-size: 24px;
    }
}

.aboutSect {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 60px;
    align-items: center;
}

.aboutText {
    flex: 1;
    box-sizing: border-box;

    h2 {
        font-size: 32px;
        margin-bottom: 15px;
    }
    p {
        font-size: 24px;
    }
}

.aboutDoubleImg {
    flex: 1;
    display: flex;
    box-sizing: border-box;
    gap: 20px;
    img {
        width: 50%;
        height: auto;
        border-radius: 10px;
    }
}

.aboutImg {
    flex: 1;
    box-sizing: border-box;

    img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .aboutCont {
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 15px;
        padding-bottom: 10rem;
    }
    
    .aboutHead {
        max-width: 100%;
    }
    
    .aboutSect {
        flex-direction: column-reverse;
    }
    
    .aboutDoubleImg {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        justify-content: space-around;
        gap: 10px;
        img {
            width: 45%;
            height: auto;
            border-radius: 10px;
        }
    }
    
    .aboutImg {
        flex: 1;
        box-sizing: border-box;
    
        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }
}