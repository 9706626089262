$bg-color: rgb(255,255,255);
$txt-color: rgb(30,41,59);
$btn-color: rgb(99,102,241);

.navCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;

    img {
        width: 65px;
    }
}

.navBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    

    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 24px;
        transition: all .15s ease-in-out;
    }
    button:hover {
        color: $btn-color;
    }

    .navLink {
        background-color: $btn-color;
        color: white;
        padding: 20px 25px;
        border-radius: 999px;
    }
    .navLink:hover {
        color: $txt-color;
    }
}

@media only screen and (max-width: 768px) {
    .navCont {
        padding: 10px 15px;
    
        img {
            width: 45px;
        }
    }
    
    .navBtns {
        gap: 10px;
        
        button {
            font-size: 16px;
        }
    
        .navLink {
            padding: 5px 10px;
        }
    }
}